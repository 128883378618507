// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * Triggered when a user selects a background in the video/photo camera
 */
export interface CameraBackgroundToggled {
    /**
     * The app source where this event was fired
     */
    appSource:      AppSource;
    backgroundType: BackgroundType;
    [property: string]: any;
}

/**
 * The app source where this event was fired
 */
export enum AppSource {
    Admin = "admin",
    Mobile = "mobile",
    Pwa = "pwa",
}

export enum BackgroundType {
    Blur = "blur",
    Image = "image",
    None = "none",
}

/**
 * Error after send verification code
 */
export interface FailedSendVerificationCode {
    channel:      Channel;
    errorMessage: string;
    errorName:    null | string;
    referrer?:    string;
    sessionId?:   string;
    /**
     * Possible values are: PWA, CMS
     */
    source: Source;
    to:     string;
    [property: string]: any;
}

export enum Channel {
    Email = "email",
    Phone = "phone",
}

/**
 * Possible values are: PWA, CMS
 */
export enum Source {
    CMS = "cms",
    Pwa = "pwa",
}

/**
 * Error after send verification code
 */
export interface FailedVerifyVerificationCode {
    channel:      Channel;
    errorMessage: string;
    errorName:    null | string;
    referrer?:    string;
    sessionId?:   string;
    source:       Source;
    to:           string;
    [property: string]: any;
}

export interface GoogleSigninAuthVerifyFailure {
    cause?:    string;
    email?:    string;
    referrer?: string;
    source?:   string;
    [property: string]: any;
}

/**
 * Failed to Signin with Google
 */
export interface GoogleSigninFailure {
    cause?:    string;
    email?:    string;
    referrer?: string;
    source?:   string;
    [property: string]: any;
}

/**
 * Successful Signin with Google
 */
export interface GoogleSigninSuccess {
    email?:     string;
    referrer?:  string;
    sessionId?: string;
    source?:    string;
    [property: string]: any;
}

/**
 * User requests a new code
 */
export interface NewCodeRequested {
    channel?:   Channel;
    referrer?:  string;
    sessionId?: string;
    source:     Source;
    to:         string;
    [property: string]: any;
}

/**
 * When selected a country
 */
export interface PhoneCountryChanged {
    previousCountry: string;
    referrer?:       string;
    selectedCountry: string;
    sessionId?:      string;
    [property: string]: any;
}

/**
 * Triggered when a user clicks on any available option of the camera or audio recorder
 */
export interface RecorderOptionClicked {
    /**
     * The app source where this event was fired
     */
    appSource:  AppSource;
    buttonType: ButtonType;
    /**
     * Rep response type
     */
    responseType: ResponseType;
    [property: string]: any;
}

export enum ButtonType {
    Backgrounds = "backgrounds",
    CameraOff = "cameraOff",
    CameraOn = "cameraOn",
    MicOff = "micOff",
    MicOn = "micOn",
    RecordScreenStarted = "recordScreenStarted",
    RecordScreenStopped = "recordScreenStopped",
    Settings = "settings",
    TeleprompterOff = "teleprompterOff",
    TeleprompterOn = "teleprompterOn",
    Upload = "upload",
}

/**
 * Rep response type
 */
export enum ResponseType {
    Audio = "audio",
    Image = "image",
    Video = "video",
}

/**
 * Tapped tabs
 */
export interface SigninOptionSelected {
    name:       string;
    referrer?:  string;
    sessionId?: string;
    [property: string]: any;
}

/**
 * Success submit verification code
 */
export interface SigninVerified {
    channel:    Channel;
    referrer?:  string;
    sessionId?: string;
    source:     Source;
    to:         string;
    [property: string]: any;
}

/**
 * On mount Phone Login screen
 */
export interface SigninViewed {
    referrer?:  string;
    sessionId?: string;
    [property: string]: any;
}

/**
 * Success Send verification
 */
export interface SuccessSendVerificationCode {
    channel:    Channel;
    referrer?:  string;
    sessionId?: string;
    source:     Source;
    to:         string;
    [property: string]: any;
}

/**
 * User logs into CMS or Client App
 */
export interface UserLoggedIn {
    companyExternalId: string;
    method:            Method | null;
    profileExternalId: string;
    referrer?:         string;
    sessionId?:        string;
    [property: string]: any;
}

export enum Method {
    Email = "email",
    Phone = "phone",
    URL = "url",
}

export interface UserLoggedOut {
    companyExternalId: string;
    profileExternalId: string;
    referrer?:         string;
    sessionId?:        string;
    [property: string]: any;
}

/**
 * Error thrown when webcam could not be loaded
 */
export interface WebcamInitiationFailed {
    companyExternalId: string;
    errorMessage:      string;
    errorName:         string;
    profileExternalId: string;
    sessionId?:        string;
    [property: string]: any;
}

/**
 * Error thrown when webcam could not be loaded
 */
export interface WebcamScreenShareFailed {
    companyExternalId: string;
    errorMessage:      string;
    errorName:         string;
    profileExternalId: string;
    sessionId?:        string;
    [property: string]: any;
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 * 
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv, { ErrorObject } from 'ajv'

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this: 
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next' 
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 * 
 * setTypewriterOptions({ analytics: analytics })
 */
import type { AnalyticsSnippet, Analytics, AnalyticsBrowser, Options } from '@segment/analytics-next'

declare global {
    interface Window {
        analytics: AnalyticsSnippet;
    }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
    message: Record<string, any>,
    violations: ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
    const msg = JSON.stringify(
        {
            type: 'Typewriter JSON Schema Validation Error',
            description:
                `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
                'Tracking Plan spec.',
            errors: violations,
        },
        undefined,
        2,
    );

    console.warn(msg);
};

let onViolation = defaultValidationErrorHandler

let analytics: () => AnyAnalytics | undefined = () => {
    return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
    /**
     * Underlying analytics instance where analytics calls are forwarded on to.
     * Defaults to window.analytics.
     */
    analytics?: AnyAnalytics;
    /**
     * Handler fired when if an event does not match its spec. This handler
     * does not fire in production mode, because it requires inlining the full
     * JSON Schema spec for each event in your Tracking Plan.
     *
     * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
     * if a message does not match the spec. Otherwise, errors will be logged to stderr.
     */
    onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
    analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
    onViolation = options.onViolation || onViolation
}

/**
    * Validates a message against a JSON Schema using Ajv. If the message
    * is invalid, the `onViolation` handler will be called.
    */
function validateAgainstSchema(
    message: Record<string, any>,
    schema: object
) {
    const ajv = new Ajv({ allErrors: true, verbose: true })

    if (!ajv.validate(schema, message) && ajv.errors) {
        onViolation(message, ajv.errors)
    }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
    return {
        ...message,
        context: {
            ...(message.context || {}),
            typewriter: {
                language: 'typescript',
                version: '9.2.0',
            },
        },
    };
}

/**
 * Fires a 'CameraBackgroundToggled' track call.
 *
 * @param CameraBackgroundToggled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cameraBackgroundToggled(props: CameraBackgroundToggled, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Camera_Background_Toggled","$schema":"http://json-schema.org/draft-07/schema#","description":"Triggered when a user selects a background in the video/photo camera","properties":{"appSource":{"$id":"/properties/appSource","description":"The app source where this event was fired","enum":["admin","mobile","pwa"],"type":"string"},"backgroundType":{"$id":"/properties/backgroundType","description":"","enum":["blur","image","none"],"type":"string"}},"required":["backgroundType","appSource"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Camera Background Toggled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FailedSendVerificationCode' track call.
 *
 * @param FailedSendVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function failedSendVerificationCode(props: FailedSendVerificationCode, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Failed_Send_Verification_Code","$schema":"http://json-schema.org/draft-07/schema#","description":"Error after send verification code","properties":{"channel":{"description":"","enum":["email","phone"],"type":"string"},"errorMessage":{"type":["string"]},"errorName":{"description":"","type":["string","null"]},"referrer":{"type":["string"]},"sessionId":{"type":["string"]},"source":{"description":"Possible values are: PWA, CMS","enum":["cms","pwa"],"type":"string"},"to":{"type":["string"]}},"required":["errorName","channel","errorMessage","source","to"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Failed Send Verification Code', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FailedVerifyVerificationCode' track call.
 *
 * @param FailedVerifyVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function failedVerifyVerificationCode(props: FailedVerifyVerificationCode, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Failed_Verify_Verification_Code","$schema":"http://json-schema.org/draft-07/schema#","description":"Error after send verification code","properties":{"channel":{"enum":["email","phone"],"type":"string"},"errorMessage":{"type":["string"]},"errorName":{"description":"","type":["string","null"]},"referrer":{"type":["string"]},"sessionId":{"type":["string"]},"source":{"description":"","enum":["cms","pwa"],"type":"string"},"to":{"type":["string"]}},"required":["source","to","errorMessage","channel","errorName"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Failed Verify Verification Code', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'GoogleSigninAuthVerifyFailure' track call.
 *
 * @param GoogleSigninAuthVerifyFailure props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function googleSigninAuthVerifyFailure(props: GoogleSigninAuthVerifyFailure, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Google_Signin_Auth_Verify_Failure","$schema":"http://json-schema.org/draft-07/schema#","properties":{"cause":{"$id":"/properties/cause","description":"","type":"string"},"email":{"$id":"/properties/email","description":"","type":"string"},"referrer":{"$id":"/properties/referrer","description":"","type":"string"},"source":{"$id":"/properties/source","description":"","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Google Signin Auth Verify Failure', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'GoogleSigninFailure' track call.
 *
 * @param GoogleSigninFailure props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function googleSigninFailure(props: GoogleSigninFailure, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Google_Signin_Failure","$schema":"http://json-schema.org/draft-07/schema#","description":"Failed to Signin with Google","properties":{"cause":{"$id":"/properties/cause","description":"","type":"string"},"email":{"$id":"/properties/email","description":"","type":"string"},"referrer":{"$id":"/properties/referrer","description":"","type":"string"},"source":{"$id":"/properties/source","description":"","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Google Signin Failure', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'GoogleSigninSuccess' track call.
 *
 * @param GoogleSigninSuccess props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function googleSigninSuccess(props: GoogleSigninSuccess, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Google_Signin_Success","$schema":"http://json-schema.org/draft-07/schema#","description":"Successful Signin with Google","properties":{"email":{"$id":"/properties/email","description":"","type":"string"},"referrer":{"$id":"/properties/referrer","description":"","type":"string"},"sessionId":{"$id":"/properties/sessionId","description":"","type":"string"},"source":{"$id":"/properties/source","description":"","type":"string"}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Google Signin Success', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NewCodeRequested' track call.
 *
 * @param NewCodeRequested props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newCodeRequested(props: NewCodeRequested, options?: Options, callback?: Callback): void {

    const schema = {"$id":"New_Code_Requested","$schema":"http://json-schema.org/draft-07/schema#","description":"User requests a new code","properties":{"channel":{"description":"","enum":["email","phone"],"type":"string"},"referrer":{"type":["string"]},"sessionId":{"type":["string"]},"source":{"enum":["cms","pwa"],"type":"string"},"to":{"type":["string"]}},"required":["source","to"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('New Code Requested', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PhoneCountryChanged' track call.
 *
 * @param PhoneCountryChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function phoneCountryChanged(props: PhoneCountryChanged, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Phone_Country_Changed","$schema":"http://json-schema.org/draft-07/schema#","description":"When selected a country","properties":{"previousCountry":{"type":["string"]},"referrer":{"type":["string"]},"selectedCountry":{"type":["string"]},"sessionId":{"type":["string"]}},"required":["previousCountry","selectedCountry"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Phone Country Changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RecorderOptionClicked' track call.
 *
 * @param RecorderOptionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function recorderOptionClicked(props: RecorderOptionClicked, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Recorder_Option_Clicked","$schema":"http://json-schema.org/draft-07/schema#","description":"Triggered when a user clicks on any available option of the camera or audio recorder","properties":{"appSource":{"$id":"/properties/appSource","description":"The app source where this event was fired","enum":["admin","mobile","pwa"],"type":"string"},"buttonType":{"$id":"/properties/buttonType","description":"","enum":["backgrounds","cameraOff","cameraOn","micOff","micOn","recordScreenStarted","recordScreenStopped","settings","teleprompterOff","teleprompterOn","upload"],"type":"string"},"responseType":{"$id":"/properties/responseType","description":"Rep response type","enum":["audio","image","video"],"type":"string"}},"required":["responseType","buttonType","appSource"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Recorder Option Clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SigninOptionSelected' track call.
 *
 * @param SigninOptionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinOptionSelected(props: SigninOptionSelected, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Signin_Option_Selected","$schema":"http://json-schema.org/draft-07/schema#","description":"Tapped tabs","properties":{"name":{"type":["string"]},"referrer":{"type":["string"]},"sessionId":{"type":["string"]}},"required":["name"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Signin Option Selected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SigninVerified' track call.
 *
 * @param SigninVerified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinVerified(props: SigninVerified, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Signin_Verified","$schema":"http://json-schema.org/draft-07/schema#","description":"Success submit verification code","properties":{"channel":{"enum":["email","phone"],"type":"string"},"referrer":{"type":["string"]},"sessionId":{"type":["string"]},"source":{"enum":["cms","pwa"],"type":"string"},"to":{"type":["string"]}},"required":["source","to","channel"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Signin Verified', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SigninViewed' track call.
 *
 * @param SigninViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signinViewed(props: SigninViewed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Signin_Viewed","$schema":"http://json-schema.org/draft-07/schema#","description":"On mount Phone Login screen","properties":{"referrer":{"type":["string"]},"sessionId":{"type":["string"]}},"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Signin Viewed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SuccessSendVerificationCode' track call.
 *
 * @param SuccessSendVerificationCode props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function successSendVerificationCode(props: SuccessSendVerificationCode, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Success_Send_Verification_Code","$schema":"http://json-schema.org/draft-07/schema#","description":"Success Send verification","properties":{"channel":{"enum":["email","phone"],"type":"string"},"referrer":{"type":["string"]},"sessionId":{"type":["string"]},"source":{"enum":["cms","pwa"],"type":"string"},"to":{"type":["string"]}},"required":["channel","source","to"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Success Send Verification Code', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserLoggedIn' track call.
 *
 * @param UserLoggedIn props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userLoggedIn(props: UserLoggedIn, options?: Options, callback?: Callback): void {

    const schema = {"$id":"User_Logged_In","$schema":"http://json-schema.org/draft-07/schema#","description":"User logs into CMS or Client App","properties":{"companyExternalId":{"type":["string"]},"method":{"description":"","enum":["email",null,"phone","url"],"type":["string","null"]},"profileExternalId":{"type":["string"]},"referrer":{"type":["string"]},"sessionId":{"type":["string"]}},"required":["companyExternalId","profileExternalId","method"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('User Logged In', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserLoggedOut' track call.
 *
 * @param UserLoggedOut props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userLoggedOut(props: UserLoggedOut, options?: Options, callback?: Callback): void {

    const schema = {"$id":"User_Logged_Out","$schema":"http://json-schema.org/draft-07/schema#","properties":{"companyExternalId":{"type":["string"]},"profileExternalId":{"type":["string"]},"referrer":{"type":["string"]},"sessionId":{"type":["string"]}},"required":["companyExternalId","profileExternalId"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('User Logged Out', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'WebcamInitiationFailed' track call.
 *
 * @param WebcamInitiationFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webcamInitiationFailed(props: WebcamInitiationFailed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Webcam_Initiation_Failed","$schema":"http://json-schema.org/draft-07/schema#","description":"Error thrown when webcam could not be loaded","properties":{"companyExternalId":{"$id":"/properties/companyExternalId","description":"","type":"string"},"errorMessage":{"$id":"/properties/errorMessage","description":"","type":"string"},"errorName":{"$id":"/properties/errorName","description":"","type":"string"},"profileExternalId":{"$id":"/properties/profileExternalId","description":"","type":"string"},"sessionId":{"$id":"/properties/sessionId","description":"","type":"string"}},"required":["profileExternalId","errorMessage","companyExternalId","errorName"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Webcam Initiation Failed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'WebcamScreenShareFailed' track call.
 *
 * @param WebcamScreenShareFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webcamScreenShareFailed(props: WebcamScreenShareFailed, options?: Options, callback?: Callback): void {

    const schema = {"$id":"Webcam_Screen_Share_Failed","$schema":"http://json-schema.org/draft-07/schema#","description":"Error thrown when webcam could not be loaded","properties":{"companyExternalId":{"$id":"/properties/companyExternalId","description":"","type":"string"},"errorMessage":{"$id":"/properties/errorMessage","description":"","type":"string"},"errorName":{"$id":"/properties/errorName","description":"","type":"string"},"profileExternalId":{"$id":"/properties/profileExternalId","description":"","type":"string"},"sessionId":{"$id":"/properties/sessionId","description":"","type":"string"}},"required":["profileExternalId","errorMessage","companyExternalId","errorName"],"type":"object"};
    validateAgainstSchema(props, schema);

    const a = analytics();
    if (a) {
        a.track('Webcam Screen Share Failed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}

const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions,

    /**
     * Fires a 'CameraBackgroundToggled' track call.
     *
     * @param CameraBackgroundToggled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cameraBackgroundToggled,
    /**
     * Fires a 'FailedSendVerificationCode' track call.
     *
     * @param FailedSendVerificationCode props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    failedSendVerificationCode,
    /**
     * Fires a 'FailedVerifyVerificationCode' track call.
     *
     * @param FailedVerifyVerificationCode props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    failedVerifyVerificationCode,
    /**
     * Fires a 'GoogleSigninAuthVerifyFailure' track call.
     *
     * @param GoogleSigninAuthVerifyFailure props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    googleSigninAuthVerifyFailure,
    /**
     * Fires a 'GoogleSigninFailure' track call.
     *
     * @param GoogleSigninFailure props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    googleSigninFailure,
    /**
     * Fires a 'GoogleSigninSuccess' track call.
     *
     * @param GoogleSigninSuccess props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    googleSigninSuccess,
    /**
     * Fires a 'NewCodeRequested' track call.
     *
     * @param NewCodeRequested props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newCodeRequested,
    /**
     * Fires a 'PhoneCountryChanged' track call.
     *
     * @param PhoneCountryChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    phoneCountryChanged,
    /**
     * Fires a 'RecorderOptionClicked' track call.
     *
     * @param RecorderOptionClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    recorderOptionClicked,
    /**
     * Fires a 'SigninOptionSelected' track call.
     *
     * @param SigninOptionSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signinOptionSelected,
    /**
     * Fires a 'SigninVerified' track call.
     *
     * @param SigninVerified props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signinVerified,
    /**
     * Fires a 'SigninViewed' track call.
     *
     * @param SigninViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signinViewed,
    /**
     * Fires a 'SuccessSendVerificationCode' track call.
     *
     * @param SuccessSendVerificationCode props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    successSendVerificationCode,
    /**
     * Fires a 'UserLoggedIn' track call.
     *
     * @param UserLoggedIn props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userLoggedIn,
    /**
     * Fires a 'UserLoggedOut' track call.
     *
     * @param UserLoggedOut props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userLoggedOut,
    /**
     * Fires a 'WebcamInitiationFailed' track call.
     *
     * @param WebcamInitiationFailed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webcamInitiationFailed,
    /**
     * Fires a 'WebcamScreenShareFailed' track call.
     *
     * @param WebcamScreenShareFailed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webcamScreenShareFailed,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
    get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method as keyof typeof clientAPI];
        }

        return () => {
            console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
            const a = analytics();
            if (a) {
                a.track(
                    'Unknown Analytics Call Fired',
                    {
                        method,
                    },
                    withTypewriterContext(),
                );
            }
        };
    },
});

